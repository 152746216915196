import React from 'react'
import Loading from '@components/SuspenseLoading/Loading'
import {getResizedImageSSR_image} from '@data/queries/components/__generated__/getResizedImageSSR'
import {getWebsiteFeaturedCategories_cached_siteCategories} from '@data/queries/components/__generated__/getWebsiteFeaturedCategories_cached'
import {categoriesQuery} from '@data/queries/components/categories'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import useWidth from '@hooks/useWidth'
import {useApolloQuery as useQuery} from 'apollo-hooks'

import Category from './Category'

import styles from './styles.module.css'

const MAX_RESOLUTION = 760
const DEFAULT_COLS_VALUES = 3

export interface FeaturedCategoriesProps {
  categories: {categoryId: string; backgroundImage: {_id: string}}[]
  title: React.ReactNode
  siteCategories: any
  showTitleOutside?: string
  cols?: number
  colsMobile?: number
  ssrCategories?: getWebsiteFeaturedCategories_cached_siteCategories
  ssrImageMap?: {[categoryId: string]: getResizedImageSSR_image}
}

function renderCategories({
  showTitleOutside = '',
  categories = [],
  siteCategories = {},
  cols,
  ssrImageMap = {}
}: {
  showTitleOutside?: string
  categories: {categoryId: string; backgroundImage: {_id: string}}[]
  siteCategories: {items?: any[]}
  cols: number
  ssrImageMap?: {[categoryId: string]: getResizedImageSSR_image}
}) {
  if (!categories && !categories.length) return
  return categories.map(({categoryId, backgroundImage}) => {
    const category = siteCategories.items.find(sc => sc._id === categoryId)
    if (!category) return
    return (
      <div
        style={{flex: `1 0 ${Math.floor(100 / cols)}%`}}
        key={categoryId}
        className={styles.categoryContainer}>
        {showTitleOutside ? <div className={styles.name}>{category.name}</div> : null}
        <Category
          showTitleOutside={showTitleOutside}
          category={category}
          backgroundImage={backgroundImage}
          ssrImage={ssrImageMap[categoryId]}
        />
      </div>
    )
  })
}

export default function FeaturedCategories(props: FeaturedCategoriesProps) {
  const websiteId = useWebsiteId()
  const screenWidth = useWidth()
  const {cols = DEFAULT_COLS_VALUES} =
    screenWidth <= MAX_RESOLUTION ? {cols: props.colsMobile} : props

  const {siteCategories: queriedCategories} = useQuery({
    query: categoriesQuery,
    variables: {websiteId},
    omit: isServerSide(),
    partial: true
  })

  const siteCategories = queriedCategories ? queriedCategories : props.ssrCategories
  if (isServerSide() && !props.ssrCategories) {
    console.warn(
      'Could not fetch categories while doing SSR. This should be fixed for an optimized experience'
    )
    return <Loading />
  }

  if (!siteCategories) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      {renderCategories({...props, siteCategories, cols})}
    </div>
  )
}
