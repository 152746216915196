import React from 'react'
import Image from '@components/Image'
import Loading from '@components/SuspenseLoading/Loading'
import {getResizedImageSSR_image} from '@data/queries/components/__generated__/getResizedImageSSR'
import {getWebsiteFeaturedCategories_cached_siteCategories_items} from '@data/queries/components/__generated__/getWebsiteFeaturedCategories_cached'
import isServerSide from '@helpers/misc/isServerSide'
import useResizedImageURL from '@hooks/useResizedImageURL'
import Link from 'next/link'
import slugify from 'slugify'

import styles from './styles.module.css'

export default function Category(props: {
  category: getWebsiteFeaturedCategories_cached_siteCategories_items
  backgroundImage: {_id: string}
  showTitleOutside: string
  ssrImage?: getResizedImageSSR_image
}) {
  const {category, backgroundImage, showTitleOutside, ssrImage} = props

  const queriedUrl = useResizedImageURL(
    {fileId: backgroundImage._id, width: 700, height: 500},
    {partial: true}
  )
  const url = queriedUrl ? queriedUrl : ssrImage?.resizedURL
  if (isServerSide() && !ssrImage?.resizedURL) {
    console.warn(
      'Could not fetch category image while doing SSR. This should be fixed for an optimized experience'
    )
    return <Loading />
  }

  return (
    <Link href={`/pedir?categoria=${encodeURIComponent(slugify(category.name, {lower: true}))}`}>
      <a className={styles.category}>
        {url ? <Image src={url} alt={category.name} layout="fill" objectFit="cover" /> : null}
        {showTitleOutside ? null : <div className={styles.name}>{category.name}</div>}
      </a>
    </Link>
  )
}
